var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('EKTable',{attrs:{"items":_vm.usersList,"columns":_vm.columns[_vm.type],"selectedLabel":"id"},on:{"details":_vm.openUsersDetails,"delete-selected":_vm.usersDeleteEvent},scopedSlots:_vm._u([{key:"items.dateBlocked",fn:function(ref){
var value = ref.value;
return [_c('b-badge',{attrs:{"variant":value ? 'danger' : 'success'}},[_vm._v(_vm._s(value ? "غير مفعل" : "مفعل"))])]}},{key:"items.dateCreated",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.moment(new Date(value)).format("YYYY/MM/DD HH:mm:ss"))+" ")]}},{key:"items.subscriptionDate",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.moment(new Date(value)).format("YYYY/MM/DD HH:mm:ss"))+" ")]}},{key:"items.facultiesIds",fn:function(ref){
var value = ref.value;
var props = ref.props;
return (_vm.type == 0)?[_c('div',{attrs:{"id":'popover-target' + props.row.id}},[_vm._v(" "+_vm._s(value == null || value.length == 0 ? "-" : value.length == 1 ? _vm.facultiesMap.get(value[0]) : _vm.facultiesMap.get(value[0]) + "...")+" ")]),(value != null && value.length > 0)?_c('b-popover',{attrs:{"target":'popover-target' + props.row.id,"triggers":"hover","placement":"top"}},[_vm._v(" "+_vm._s(_vm.formatFaculties(value))+" ")]):_vm._e()]:undefined}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }